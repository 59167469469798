import PropTypes from 'prop-types'
import React from 'react'
import tw from 'twin.macro'
import { global } from '../styles/global'
import { HTMLType, LinkType, StyleType } from '../utils/prop-types'
import Button from './button'
import Heading from './heading'

const LinkBanner = ({ title, description, callToAction, style, buttonTheme }) => {
  return (
    <div css={[global`container`, tw`flex flex-col items-start`, style]}>
      <Heading headingType="h3" content={title} style={tw`font-normal text-primary-500`} />
      {description && (
        <Heading
          headingType="h6"
          content={description}
          style={tw`mt-4 font-normal text-primary-500`}
        />
      )}
      <Button
        style={tw`mt-12`}
        type="primary"
        size="base"
        theme={buttonTheme}
        label={callToAction?.label}
        link={callToAction?.link}
      />
    </div>
  )
}

LinkBanner.defaultProps = {
  buttonTheme: 'navy-yellow',
}

LinkBanner.propTypes = {
  title: HTMLType,
  description: HTMLType,
  callToAction: LinkType,
  style: StyleType,
  buttonTheme: PropTypes.string,
}

export default LinkBanner

import groupBy from 'lodash/groupBy'
import kebabCase from 'lodash/kebabCase'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import React from 'react'
import tw, { styled } from 'twin.macro'
import Accordion from './contact-accordion'
import Image from './image'
import Heading from './heading'
import Text, { inlineTypography } from './text'

const Location = styled.div`
  ${tw`flex flex-col items-start lg:col-span-4`}
`

const LocationGroup = styled.div`
  ${tw`flex flex-col w-full space-y-20 lg:(grid grid-cols-12 gap-default space-y-0 gap-y-24 gap-x-8)`}
`

const Locations = ({ headquarters, offices, headquartersLabel, officesLabel }) => {
  const regions = groupBy(offices, (o) => o.region)

  return (
    <div css={tw`flex flex-col`}>
      <div css={tw`flex flex-col items-start mb-20`}>
        <Heading
          headingType="h2"
          content={headquartersLabel}
          style={tw`text-primary-500 mb-10 lg:mb-20`}
        />
        <LocationGroup>
          {headquarters &&
            headquarters.map((hq) => (
              <Location key={hq.titleNode} id={kebabCase(hq.titleNode)}>
                <div css={tw`flex flex-col items-start w-full`}>
                  <Image image={hq.image} style={tw`h-full w-full`} />
                </div>
                <Heading
                  headingType="h4"
                  content={hq.titleNode}
                  style={tw`text-primary-500 font-medium mb-8 mt-6`}
                />
                <Text content={hq.subtitleNode} style={inlineTypography} />
                <div
                  css={tw`flex flex-col items-start lg:mb-0 border-primary-500 border-opacity-10
                divide-y divide-grey divide-opacity-15 mt-6 w-full`}
                >
                  <Heading
                    headingType="h5"
                    content={hq.descriptionNode}
                    style={tw`text-secondary-500 font-medium mb-8 mt-6`}
                  />
                </div>
              </Location>
            ))}
        </LocationGroup>
      </div>
      <div>
        {officesLabel && (
          <Heading
            headingType="h4"
            content={officesLabel}
            style={tw`text-primary-500 mb-10 lg:mb-8`}
          />
        )}
        <Accordion>
          {map(regions, (region, label) => (
            <LocationGroup key={label}>
              {region.map((office) => (
                <Location key={office.title} id={kebabCase(office.title)}>
                  <Heading
                    headingType="h5"
                    content={office.title}
                    style={tw`text-primary-500 font-medium mb-8`}
                  />
                  <Text content={office.locationAddressNode} />
                </Location>
              ))}
            </LocationGroup>
          ))}
        </Accordion>
      </div>
    </div>
  )
}

Locations.propTypes = {
  headquarters: PropTypes.array,
  offices: PropTypes.array,
  headquartersLabel: PropTypes.object,
  officesLabel: PropTypes.string,
}

export default Locations

import { graphql } from 'gatsby'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useMemo, useState, useEffect } from 'react'
import tw from 'twin.macro'
import Heading from '../../components/heading'
import Layout from '../../components/layout'
import LinkBanner from '../../components/link-banner'
import ContactDropdown from '../../components/contact-dropdown'
import Text from '../../components/text'
import { global } from '../../styles/global'
import { flatten } from '../../utils/data'
import Button from '../../components/button'

const ContactPage = ({ data: { contact } }) => {
  const page = useMemo(
    () =>
      flatten(contact || {}, [
        'hero',
        'enquiry',
        'contactHeader',
        'claimHeader',
        'headquartersHeader',
        'demo',
      ]),
    [contact]
  )

  return (
    <Layout pageTags={page.seoMetaTags} noIndex={page.noIndex} headerTransition={true}>
      <section
        id="contact"
        css={tw`relative bg-grey-light -mt-6 pt-34 lg:(pb-4) md:pb-0 lg:(pt-46 pb-40) xl:pb-48`}
      >
        <div css={[global`layout.container`, global`layout.grid`, tw`h-full`]}>
          <div css={tw`col-span-4 md:col-span-8 lg:(col-span-5) xl:col-span-5`}>
            <div css={tw`sticky flex flex-col items-start top-32`}>
              <Heading
                content={get(page, 'contactHeader.titleNode')}
                headingType="h1"
                style={[global`base.bold-to-medium`]}
              />
              <Text content={get(page, 'contactHeader.descriptionNode')} style={tw`mt-6`} />
            </div>
          </div>
          <div
            css={tw`flex flex-col py-20 space-y-12 col-span-4 md:col-span-8
            lg:(mt-20 space-y-0 grid grid-cols-2 col-start-6 col-end-13 gap-y-16 gap-x-16)
            xl:(gap-x-40 col-start-7 col-end-17)`}
          >
            {get(page, 'contactCta').map((contact, i) => (
              <div key={i} css={tw`flex flex-col items-start`}>
                <Heading
                  content={contact.titleNode}
                  headingType="h3"
                  style={[global`base.bold-to-medium`, tw`leading-h1 font-medium`]}
                />
                <Text content={contact.subtitleNode} style={tw`mt-6`} />
                {contact.descriptionNode && (
                  <Heading
                    content={contact.descriptionNode}
                    headingType="h5"
                    style={tw`mt-6 text-secondary-500 font-medium`}
                  />
                )}
                {contact.callToAction && (
                  <Button
                    type="primary"
                    size="sm"
                    theme="transparent-transparent"
                    label={contact.callToAction.label}
                    link={contact.callToAction.link}
                    style={tw`mt-6 border border-primary-500`}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
      {page.headquartersHeader !== undefined && (
        <section id="locations" css={tw`bg-white py-24 lg:(pb-32)`}>
          <div css={[global`layout.container`]}>
            <ContactDropdown
              headquarters={get(page, 'headquarters')}
              offices={get(page, 'branchOffices')}
              headquartersLabel={get(page, 'headquartersHeader.titleNode')}
            />
          </div>
        </section>
      )}
      {page.demo !== undefined && (
        <section id="demo" css={tw`py-20 bg-secondary-920 lg:py-24`}>
          <div css={[global`layout.container`, global`layout.grid`]}>
            <LinkBanner
              title={get(page, 'demo.titleNode')}
              callToAction={get(page, 'demo.callToAction')}
              style={tw`col-span-4 md:col-span-8 lg:(col-start-1 col-end-11)`}
              buttonTheme="yellow-navy"
            />
          </div>
        </section>
      )}
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    contact: PropTypes.object.isRequired,
  }),
}

export default ContactPage

export const query = graphql`
  query NewContactQuery($locale: String!) {
    contact: datoCmsParentNewContact(locale: { eq: $locale }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      noIndex
      contactHeader {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      contactCta {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        subtitleNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          link
          disabled
        }
      }
      headquartersHeader {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
      }
      headquarters {
        image {
          format
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        imageMobile {
          format
          gatsbyImageData(layout: FULL_WIDTH)
        }
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        subtitleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      branchOffices {
        title
        location {
          latitude
          longitude
        }
        region
        locationAddressNode {
          childMarkdownRemark {
            html
          }
        }
      }
      demo {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          link
          disabled
        }
      }
    }
  }
`
